/* Section page feature carousel */
.ui-feature-carousel {
	position: relative;
	margin-bottom: 60px;
}

.carousel-item:after {
	content: '';
	display: block;
	height: 52px;
	background: var(--common-white);
}

.carousel-item__wrapper {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	min-height: 750px;
	padding: 0 15px;
	background: var(--info-main);
	color: var(--common-white);
}

.carousel-item__content-wrapper {
	display: flex;
	box-sizing: border-box;
	flex-direction: column;
	align-self: center;
	justify-content: center;
	max-width: 400px;
	min-height: 472px;
}

.carousel-item__title {
	margin: 30px 0 10px;
	font-size: var(--font-size-body1);
	font-weight: normal;
	line-height: 1.57;
	text-transform: uppercase;
}

.carousel-item__subtitle {
	margin-bottom: 30px;
	font-size: var(--font-size-h3);
	font-weight: bold;
	line-height: 1.17;
}

.carousel-item__list {
	display: flex;
	flex-direction: column;
}

.carousel-item__list-element {
	display: inline-flex;
	margin-bottom: 20px;
	font-size: var(--font-size-body1);
	font-weight: bold;
	line-height: 1.5;
	list-style: none;
}

.carousel-item__list-element:last-child {
	margin-bottom: 0;
}

.v-icon-agree-heavy:before {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 15px;
	border: 2px solid;
	border-radius: 50%;
	line-height: 20px;
}

.carousel-item__buttons-container {
	display: flex;
	flex-direction: column;
	margin-top: 20px;
}

.carousel-item__button {
	margin: 0 0 10px;
	padding: 0 5px;
	overflow: hidden;
	border: 2px solid;
	border-radius: 25px;
	color: var(--common-white);
	font-size: var(--font-size-body1);
	line-height: 3.21;
	text-align: center;
	text-decoration: none;
	text-overflow: ellipsis;
	text-transform: uppercase;
	white-space: nowrap;
}

.carousel-item__button:focus {
	box-shadow: none;
	color: var(--grey-600);
}

.carousel-item__button:hover {
	border-color: transparent;
	background: hsla(0, 0%, 100%, 0.5);
	box-shadow: 0 3px 8px 0 rgba(51, 51, 51, 0.1);
	color: var(--common-white);
}

.carousel-item__image {
	position: relative;
	bottom: -22px;
	order: 2;
	width: 330px;
	min-width: 330px;
	height: 330px;
	min-height: 330px;
	overflow: hidden;
	border-radius: 50%;
}

.carousel-item__image img {
	width: 100%;
	height: 100%;
	object-fit: cover;
}

.rsNav.rsBullets {
	bottom: -30px;
}

@media screen and (min-width: 1023px) {
	.rsArrowRight {
		right: 30px;
	}

	.rsArrowLeft {
		left: 30px;
	}

	.carousel-item:before,
	.carousel-item:after {
		content: '';
		display: block;
		height: 29px;
		background: var(--common-white);
	}

	.carousel-item__wrapper {
		flex-direction: row-reverse;
		justify-content: space-evenly;
		max-width: 1366px;
		height: 392px;
		min-height: unset;
	}

	.carousel-item__image {
		bottom: unset;
		width: 450px;
		height: 450px;
	}

	.carousel-item__content-wrapper {
		max-width: 536px;
	}

	.carousel-item__title {
		margin: 0 0 10px;
		font-size: var(--font-size-body1);
		line-height: 1.5;
	}

	.carousel-item__subtitle {
		margin-bottom: 20px;
	}

	.carousel-item__list-element {
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 2;
		position: relative;
		min-height: 24px;
		margin-bottom: 15px;
		padding-left: 35px;
		overflow: hidden;
		line-height: 19px;
	}
	.carousel-item__list-element::before {
		position: absolute;
		top: 0;
		left: 0;
	}

	.carousel-item__buttons-container {
		flex-direction: unset;
	}

	.carousel-item__button {
		width: auto;
		height: 45px;
		margin: 0 20px 0 0;
		padding: 0 35px;
		font-size: var(--font-size-body1);
	}
}
